<ng-container *ngIf="isSearchDone$ | async; else loading">
  <div *ngIf="searchResult$ | async as searchResult" @dsFadeIn>
    <ng-container
      *ngIf="
        searchResult.equipments?.length ||
          searchResult.products?.product_categories?.length ||
          searchResult.spareparts?.length ||
          searchResult.documents?.document_classes?.length;
        else noResult
      "
    >
      <ds-equipments
        *ngIf="searchResult.equipments?.length"
        [equipments]="searchResult.equipments"
        [viewAllByDefault]="scope !== ''"
        [paldeskBasePath]="paldeskBasePath"
        (itemClick)="storeSelectedResult($event)"
      ></ds-equipments>
      <ds-products
        *ngIf="searchResult.products?.product_categories?.length"
        [products]="searchResult.products"
        [viewAllByDefault]="scope !== ''"
        [paldeskBasePath]="paldeskBasePath"
        (itemClick)="storeSelectedResult($event)"
      ></ds-products>
      <ds-spareparts
        *ngIf="searchResult.spareparts?.length"
        [spareparts]="searchResult.spareparts"
        [viewAllByDefault]="scope !== ''"
        [paldeskBasePath]="paldeskBasePath"
        (itemClick)="storeSelectedResult($event)"
      ></ds-spareparts>
      <ds-documents
        *ngIf="searchResult.documents?.document_classes?.length"
        [documents]="searchResult.documents"
        [viewAllByDefault]="scope !== ''"
        [paldeskBasePath]="paldeskBasePath"
        (itemClick)="storeSelectedResult($event)"
      ></ds-documents>
    </ng-container>
    <ng-template #noResult>
      <ds-text-indicator
        *ngIf="
          searchResult.applications ||
          searchResult.equipments ||
          searchResult.products ||
          searchResult.spareparts ||
          searchResult.documents
        "
        >{{ 'header.search.no_result' | translate }}</ds-text-indicator
      >
    </ng-template>
  </div>
</ng-container>
<ng-template #loading>
  <div class="full-height overflow-hidden" @dsFade>
    <div
      class="loading-container"
      fxLayout="column"
      [fxLayoutGap]="1 | dsSpacing"
      *ngFor="let i of [1, 2, 3, 4, 5]"
    >
      <ds-loading-placeholder
        *ngFor="let i of [1, 2, 3, 4, 5]"
      ></ds-loading-placeholder>
    </div>
  </div>
</ng-template>
