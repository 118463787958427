import { createReducer, on } from '@ngrx/store';
import { SearchActions, SearchItem } from './search.actions';

export const key = 'search';

export interface SearchState {
  searchBase: SearchItem[];
  searchResult: string[];
  isLoading: boolean;
}

export const initialState: SearchState = {
  searchBase: [] as SearchItem[],
  searchResult: [] as string[],
  isLoading: true,
};

const _searchReducer = createReducer(
  initialState,
  on(SearchActions.LoadBaseSuccess, (state, { base }) => ({
    ...state,
    searchBase: base,
  })),

  on(SearchActions.GetResults, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(SearchActions.GetResultsSuccess, (state, { result }) => ({
    ...state,
    searchResult: result,
    isLoading: false,
  })),
);

export function reducer(state, action) {
  return _searchReducer(state, action);
}
