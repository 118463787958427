import { InjectionToken } from '@angular/core';

export interface HealthCheckBannerOptions {
  productsToCheck: string[];
  statusPaldeskApiUri: string;
  statusPaldeskPageUri: string;
}

export const HEALTH_CHECK_OPTIONS_TOKEN = new InjectionToken<HealthCheckBannerOptions>(
  'HEALTH_CHECK_OPTIONS_TOKEN',
);
