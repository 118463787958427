<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="center center">
    <h2>Welcome to</h2>
    <h1 class="palfinger-font no-margin-top text-center">
      Paldesk Design System
    </h1>
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput [formControl]="searchInput" />
    </mat-form-field>
  </div>
  <ng-container
    *pdLet="{
      results: results$ | async,
      isLoading: isLoading$ | async,
      base: base$ | async
    } as data"
  >
    <div
      fxFlex="0 1 auto"
      class="no-min-height scroll-container-vertical full-height full-width"
      fxLayout="row wrap"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      *ngIf="data.results?.length || data.isLoading; else noResultsView"
    >
      <ds-docu-preview
        *ngFor="let title of data.results"
        [fxFlex]="'0 0 ' + (20 | dsSpacing)"
        fxFlex.lt-xl="0 0 33%"
        fxFlex.lt-lg="0 0 50%"
        fxFlex.xs="0 0 100%"
        [heading]="title"
        [link]="title | findRoute: data.base"
        [imageUrl]="
          'assets/img/' + (title.replace(regex, '-') | lowercase) + '.png'
        "
      >
      </ds-docu-preview>
    </div>
    <ng-template #noResultsView>
      <ds-placeholder customHeadline="No results found..." type="no_data"
        ><p>
          Please try some different key words or contact the DS-Team for help
          bellow.
        </p>
        <a
          mat-button
          target="_blank"
          href="https://teams.microsoft.com/l/channel/19%3abe394be93baa478190a76f8c5a66a2fb%40thread.skype/Design%2520System?groupId=6564470d-0659-4577-ac5f-e3e602025ec7&tenantId=6769af3e-92cd-439b-9059-74c409b76204"
          >Contact</a
        >
      </ds-placeholder>
    </ng-template></ng-container
  >
</div>
