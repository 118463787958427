import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContractModel, DpaData } from '../dpa.models';
import { DpaService } from '../dpa.service';

@Component({
  selector: 'paldesk-dpa-template',
  templateUrl: './dpa-template.component.html',
  styleUrls: ['./dpa-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DPATemplateComponent {
  @Input() data: DpaData;
  @Input() set contract(contract_: ContractModel) {
    if (contract_ && this.data) {
      this._intl = contract_;
      this.responsiblePerson =
        this.data.responsible_person || contract_.responsible_person;
      this.signatureUri = this.dpaService.getSignatureUrl(
        this.responsiblePerson,
      );
    }
  }

  today = new Date();
  _intl: ContractModel;
  responsiblePerson: string;
  signatureUri: string;

  constructor(private dpaService: DpaService) {}
}
