<ng-container *ngIf="history$ | async as history">
  <h4>
    <small>{{ 'header.search.recent' | translate }}</small>
  </h4>
  <mat-action-list dense>
    <a
      class="no-underline"
      mat-list-item
      [href]="
        item.url?.indexOf('http') === -1 ? paldeskBasePath + item.url : item.url
      "
      target="_blank"
      *ngFor="let item of history"
    >
      <div class="full-width" fxLayout fxLayoutAlign="space-between">
        <div
          fxLayout
          fxLayoutAlign="start center"
          [fxLayoutGap]="0.5 | dsSpacing"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.description }}</span>
        </div>
        <button
          mat-icon-button
          (click)="$event.preventDefault(); removeResult(item)"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </a>
    <button mat-list-item (click)="clearHistory()">
      <h5 class="no-margin">
        <small
          fxLayout
          fxLayoutAlign="start center"
          [fxLayoutGap]="0.5 | dsSpacing"
        >
          <span>{{ 'header.search.clear-history' | translate }}</span>
        </small>
      </h5>
    </button>
  </mat-action-list>
</ng-container>
