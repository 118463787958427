import { Component, Inject, Optional } from '@angular/core';
import { DsSpotlight } from '@design-system/feature/spotlight';
import { UserService } from '@features/auth';
import { AppWrapperService } from '../app-wrapper.service';
import {
  IDENTITY_SERVER_BASEPATH,
  PALDESK_BASEPATH,
  PALDESK_DATA_PROTECTION_REGULATION_URL,
  PALDESK_SUPPORT_PATH,
  TELEMATIC_SUPPORT_ENABLED,
} from '../environment';

@Component({
  selector: 'ds-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  paldeskBasePath = 'https://paldesk.palfinger.com';
  identityServerBasePath = 'https://identity.palfinger.com';
  paldeskSupportPath = 'https://status-paldesk.palfinger.com';
  hasTelematicSupport = false;
  copyrightYear = new Date().getFullYear();

  constructor(
    public userService: UserService,
    @Optional() @Inject(PALDESK_BASEPATH) paldeskBasePath: string,
    @Optional() @Inject(PALDESK_SUPPORT_PATH) paldeskSupportPath: string,
    @Optional()
    @Inject(IDENTITY_SERVER_BASEPATH)
    identityServerBasePath: string,
    @Optional()
    @Inject(PALDESK_DATA_PROTECTION_REGULATION_URL)
    readonly paldeskDataProtectionRegulationUrl: string,
    @Optional() @Inject(TELEMATIC_SUPPORT_ENABLED) hasTelematicSupport: boolean,
    private _dsSpotlight: DsSpotlight,
    private wrapperService: AppWrapperService,
  ) {
    if (paldeskBasePath) {
      this.paldeskBasePath = paldeskBasePath;
    }

    if (identityServerBasePath) {
      this.identityServerBasePath = identityServerBasePath;
    }

    if (paldeskSupportPath) {
      this.paldeskSupportPath = paldeskSupportPath;
    }

    if (hasTelematicSupport) {
      this.hasTelematicSupport = hasTelematicSupport;
    }
  }

  logout() {
    this.userService.logout();
  }

  login() {
    this.userService.login();
  }

  openUserProfile(): void {
    this.userService.navigateToProfile();
  }

  resetSpotlight() {
    this._dsSpotlight.resetWholeSpotlight();
    this.wrapperService.drawerClose();
  }
}
