<h1>Merry Xmas and Happy New Year!</h1>

<div class="scene">
  <div class="tree">
    <img #tree class="xmas-tree" src="assets/img/christmas_tree.png" />
  </div>
  <img class="logo" src="assets/img/logo.svg" />
  <div class="icons">
    <div *ngFor="let icon of icons">
      <div
        cdkDrag
        (cdkDragStarted)="started($event)"
        (cdkDragEnded)="drop($event)"
        cdkDragBoundary=".scene"
      >
        <div>
          <img class="icon" [src]="'assets/img/product-icons/' + icon" />
          <span class="rope"></span>
        </div>
      </div>
    </div>
  </div>
</div>
