import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractModel } from './dpa.models';

@Injectable({
  providedIn: 'root',
})
export class DpaService {
  private readonly placeholder = '{0}';
  private readonly textUriTemplate = `https://sacoreservices.blob.core.windows.net/dpa/text/${this.placeholder}.json`;
  private readonly signatureUriTemplate = `https://sacoreservices.blob.core.windows.net/dpa/signature/${this.placeholder}.png`;

  constructor(private http: HttpClient) {}

  getText(language: string): Observable<ContractModel> {
    const dpaTextUri = this.textUriTemplate.replace(this.placeholder, language);
    return this.http.get<ContractModel>(dpaTextUri);
  }

  getSignatureUrl(responsiblePerson: string): string {
    return this.signatureUriTemplate.replace(
      this.placeholder,
      responsiblePerson.toLowerCase().replace(' ', '_'),
    );
  }
}
