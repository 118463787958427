import { Component, Input } from '@angular/core';
import { dsAnimations } from '@design-system/cdk/animations';
import {
  SearchActions,
  SearchResult,
  SearchResultLink,
  SearchSelector,
  SearchState,
} from '@features/search';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [dsAnimations.fade, dsAnimations.fadeIn],
})
export class SearchResultComponent {
  @Input() scope: string;
  @Input() paldeskBasePath: string;
  isSearchDone$: Observable<boolean>;
  searchResult$: Observable<SearchResult.Container>;

  constructor(private store: Store<SearchState>) {
    this.searchResult$ = this.store.select(SearchSelector.searchResults);

    this.isSearchDone$ = this.store
      .select(SearchSelector.searchPending)
      .pipe(map((isSearchPending) => !isSearchPending));
  }

  storeSelectedResult(link: SearchResultLink) {
    this.store.dispatch(
      SearchActions.AddSearchResult({
        key: 'global-search',
        result: link,
      }),
    );
  }
}
