<div fxLayout="row">
  <div>
    <button
      mat-icon-button
      type="button"
      (click)="copySource(code)"
      class="copy-button"
      matTooltip="Copy snippet"
      title="Copy example source"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
    <pre><code [highlight]="code"></code></pre>
  </div>
</div>
