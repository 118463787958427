import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  Input,
  OnDestroy,
  ReflectiveInjector,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { filterTruthy } from '@shared-lib/rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DsSidebarService } from '../page-sidebar.service';

@Component({
  selector: 'ds-page-sidebar-content',
  templateUrl: './page-sidebar-content.component.html',
  styleUrls: ['./page-sidebar-content.component.scss'],
})
export class DsPageSidebarContentComponent implements OnDestroy {
  @Input() set component(value: ComponentType<any>) {
    this.componentType = value;
    this.createComponent(value);
  }

  @Input() backUrl?: string;
  @Input() isExpanded: boolean;
  @Input() paldeskUrl: string;

  @ViewChild('componentOutlet', {
    read: ViewContainerRef,
    static: true,
  })
  componentOutlet: ViewContainerRef;

  private componentType: ComponentType<any>;
  private componentFactoryResolver: ComponentFactoryResolver;
  private injector: Injector;
  private componentRef: ComponentRef<any>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private sidebarService: DsSidebarService,
    public media: MediaObserver,
    private router: Router,
  ) {
    this.sidebarService.context$
      .pipe(filterTruthy(), takeUntil(this.destroy$))
      .subscribe((resolver) => {
        this.componentFactoryResolver = resolver.resolver;
        this.injector = resolver.injector;
        this.createComponent(this.componentType);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  navigateBack() {
    this.router.navigate(['/']).then(() => {
      if (this.backUrl) {
        this.router.navigate([this.backUrl]);
      }
    });
  }

  private createComponent(component: ComponentType<any>) {
    if (
      component &&
      this.componentFactoryResolver &&
      this.injector &&
      this.componentOutlet
    ) {
      // provide context (support lazy loading)
      const sidebarContent =
        this.componentFactoryResolver.resolveComponentFactory(component);
      // tslint:disable-next-line: deprecation
      const refInjector = ReflectiveInjector.resolveAndCreate(
        [{ provide: component, useValue: component }],
        this.injector,
      );
      // clean up
      this.componentOutlet.clear();
      if (this.componentRef) {
        this.componentRef.destroy();
      }
      // create new
      this.componentRef = this.componentOutlet.createComponent(
        sidebarContent,
        0,
        refInjector,
      );

      // trigger inital change detection
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }
}
