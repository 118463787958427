import { Inject, Injectable, Optional } from '@angular/core';
import { UserContextService } from '@features/auth';
import {
  ApplicationInsights,
  ITraceTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import {
  IMessage,
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import {
  APPLICATION_INSIGHTS_KEY,
  APPLICATION_INSIGHTS_MIN_LOG_LEVEL,
} from './applicationinsights-tokens';
import { FastFeedbackMsg } from './applicationinsights.models';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private readonly appInsights: ApplicationInsights;

  constructor(
    private messageService: MessengerService,
    private userContextService: UserContextService,
    @Inject(APPLICATION_INSIGHTS_KEY) private applicationInsightsKey: string,
    @Inject(APPLICATION_INSIGHTS_MIN_LOG_LEVEL)
    @Optional()
    private applicationInsightsMinLogLevel: number | undefined,
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.applicationInsightsKey,
        enableAutoRouteTracking: true, // option to log all route changes
        loggingLevelTelemetry: applicationInsightsMinLogLevel || 1, // default log level is 1-Information
      },
    });
  }

  startTracking(roleName: string) {
    if (!this.applicationInsightsKey || !this.applicationInsightsKey.length) {
      // eslint-disable-next-line no-console
      console.log('AppInsightsKey is not provided');
      return;
    }
    this.appInsights.loadAppInsights();
    const telemetryInitializer = (envelope: any) => {
      envelope.tags['ai.device.roleName'] = roleName;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);

    //track logs
    this.messageService
      .of({ targets: MessageTargetType.log })
      .subscribe((msg) => {
        if (this.appInsights) {
          const telemetry: ITraceTelemetry = {
            message: msg.message,
            properties: this.extractProperties(msg),
            severityLevel: this.convertSeverity(msg.severity),
          };
          try {
            this.appInsights.trackTrace(telemetry);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(
              'Error while sending message to ApplicationInsights',
              e,
            );
          }
        }
      });
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  storeFastFeedback(msg: FastFeedbackMsg) {
    const properties = {
      Message: msg.message,
      Happy: msg.happy.toString(),
      ContactMe: msg.contactMe.toString(),
      AppId: msg.appId,
      Screen: msg.screen,

      UserName: msg.contactMe
        ? this.userContextService.userContext?.username
        : 'N/A',
      UserEmail: msg.contactMe
        ? this.userContextService.userContext?.email
        : 'N/A',
      Country: this.userContextService.userContext?.country || 'N/A',
    };

    this.appInsights.trackTrace(
      { message: 'FastFeedback', severityLevel: SeverityLevel.Information },
      properties,
    );
  }

  private convertSeverity(
    severity: MessageSeverityType,
  ): SeverityLevel | undefined {
    try {
      switch (severity) {
        case MessageSeverityType.trace: {
          return SeverityLevel.Verbose;
        }
        case MessageSeverityType.debug: {
          return SeverityLevel.Information;
        }
        case MessageSeverityType.info: {
          return SeverityLevel.Information;
        }
        case MessageSeverityType.warning: {
          return SeverityLevel.Warning;
        }
        case MessageSeverityType.error: {
          return SeverityLevel.Error;
        }
        case MessageSeverityType.fatal: {
          return SeverityLevel.Critical;
        }
        default:
          return SeverityLevel.Information;
      }
    } catch {
      // at early stages AI is not defined
      return undefined;
    }
  }

  private extractProperties(msg: IMessage<any>): { [name: string]: string } {
    const properties: any = {
      severity: msg.severity,
      message: msg.message,
      externalID: msg.externalID || '',
      source: msg.source || '',
      sourceInstance: msg.sourceInstance || '',
      stacktrace: msg.stacktrace || '',
      url: msg.url || '',
      username:
        msg.username || this.userContextService.userContext?.username || 'N/A',
      clientMessageID: msg.clientMessageID || '',
    };

    properties['duration'] = msg?.duration?.toString();
    properties['time'] = msg?.time?.toString();

    this.setUrls(properties);

    properties['detailText'] =
      msg?.detailText && Array.isArray(msg.detailText)
        ? msg.detailText.join('\n')
        : msg.detailText;

    if (Array.isArray(msg?.targets)) {
      let j = 0;
      properties['targets'] = msg.targets.map((p) => p.toString()).join('|');
      msg.targets.forEach((t) => {
        properties['target_' + j++] = t.toString();
      });
    } else {
      properties['target_0'] = msg?.targets?.toString();
    }

    if (Array.isArray(msg?.tags)) {
      let i = 0;
      properties['tags'] = msg.tags.join('\n');
      msg.tags.forEach((tg) => {
        properties['tag_' + i++] = tg.toString();
      });
    } else {
      properties['tag_0'] = msg?.tags?.toString();
    }

    try {
      properties['sourceInstance'] = JSON.stringify(msg?.sourceInstance);
      properties['detailObject'] = JSON.stringify(msg?.detailObject);
    } catch {
      // there might be some issues with serializing, just swallow in this case
    }

    return properties;
  }

  private setUrls(properties: any) {
    if (window?.location?.pathname) {
      let currentUrl = window.location.pathname;
      const urlParts = currentUrl.split('/');
      if (urlParts.length >= 2) {
        properties['module'] = urlParts[1];
      }

      if (window.location.search) {
        currentUrl = currentUrl + window.location.search;
      }
      properties['currentUrl'] = currentUrl;
    }
  }
}
