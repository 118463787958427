import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DsBannerModule } from '@design-system/components/banner';
import { TranslateModule } from '@ngx-translate/core';
import { HcStatusBannerComponent } from './hc-status-banner.component';
@NgModule({
  imports: [CommonModule, DsBannerModule, TranslateModule, MatButtonModule],
  declarations: [HcStatusBannerComponent],
  exports: [HcStatusBannerComponent],
})
export class HcStatusBannerModule {}
