import { createAction, props } from '@ngrx/store';

export interface SearchItem {
  title: string;
  route: string;
}

export namespace SearchActions {
  export const LoadBase = createAction('[DesignSystem] LOAD_SEARCH_BASE');
  export const LoadBaseSuccess = createAction(
    '[DesignSystem] LOAD_SEARCH_BASE_SUCCESS',
    props<{ base: SearchItem[] }>(),
  );

  export const GetResults = createAction(
    '[DesignSystem] GET_SEARCH_RESULTS',
    props<{ search: string }>(),
  );
  export const GetResultsSuccess = createAction(
    '[DesignSystem] GET_SEARCH_RESULTS_SUCCESS',
    props<{ result: string[] }>(),
  );
}
