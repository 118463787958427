import { ComponentType } from '@angular/cdk/portal';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { DsSidebarService } from '../page-sidebar.service';

interface DsSidebarContent {
  component: ComponentType<any>;
  url: string;
}

@Component({
  selector: 'ds-page-sidebar-configuration',
  templateUrl: './page-sidebar-configuration.component.html',
  styleUrls: ['./page-sidebar-configuration.component.scss'],
})
export class DsPageSidebarConfigurationComponent implements OnInit, OnDestroy {
  @Input() isExpanded: boolean;
  @Input() paldeskUrl: string;
  sidebarContents: DsSidebarContent[];
  backUrl?: string;
  animateSidebar = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private sidebarService: DsSidebarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateSidebar();
      });

    this.sidebarService.context$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateSidebar();
      });
  }

  ngOnInit() {
    this.updateSidebar();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private shouldUpdateSidebar(newSidebar: DsSidebarContent[]): boolean {
    if (!this.sidebarContents) return true;
    if (this.sidebarContents.length !== newSidebar.length) return true;
    for (let i = 0; i < newSidebar.length; ++i) {
      if (
        newSidebar[i].url !== this.sidebarContents[i].url ||
        newSidebar[i].component.name !== this.sidebarContents[i].component.name
      )
        return true;
    }
    return false;
  }

  private updateSidebar() {
    const newSidebarContents = this.getSidebarContentsFromRoutes(
      this.activatedRoute.root,
    );

    if (!this.shouldUpdateSidebar(newSidebarContents)) return;

    // if there is a set of contents already, check if we need to animate
    if (this.sidebarContents && this.sidebarContents.length > 0) {
      // if current set is subset of new or if new set is subset of current
      if (
        this.sidebarContents.every((x) =>
          newSidebarContents.find((y) => y.component === x.component),
        ) ||
        newSidebarContents.every((x) =>
          this.sidebarContents.find((y) => y.component === x.component),
        )
      ) {
        this.animateSidebar = true;
      }
    } else {
      this.animateSidebar = false;
    }

    this.sidebarContents = newSidebarContents;
  }

  private getSidebarContentsFromRoutes(
    route: ActivatedRoute,
    url: string = '',
    sidebarComponents: DsSidebarContent[] = [],
  ): DsSidebarContent[] {
    let content: DsSidebarContent | null = null;

    if (route.routeConfig?.data && route.routeConfig.data['backUrl']) {
      this.backUrl = route.routeConfig.data['backUrl'];
    }

    // if route is not configured we don't add a sidebar component
    if (
      route &&
      route.routeConfig &&
      route.routeConfig.data &&
      route.routeConfig.data['sidebar']
    ) {
      content = {
        component: route.routeConfig.data['sidebar'],
        url: url + '/' + route.snapshot.url.join('/'),
      };
      sidebarComponents = [...sidebarComponents, content];
    }

    // If we are not on our current path yet,
    // there will be more children to look after, to build our breadcumb
    if (route.firstChild) {
      return this.getSidebarContentsFromRoutes(
        route.firstChild,
        content ? content.url : url,
        sidebarComponents,
      );
    }

    return sidebarComponents;
  }
}
