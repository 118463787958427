<h1 class="no-margin-top">{{ 'customer.btn_search' | translate }}</h1>
<div fxLayout="column" class="full-height">
  <div
    class="full-width"
    fxFlex="0 0 auto"
    fxLayout="row wrap"
    [fxLayoutGap]="0.5 | dsSpacing"
  >
    <mat-form-field fxFlex="0 0 {{ 7 | dsSpacing }}">
      <mat-select [formControl]="scopeForm">
        <mat-option [value]="''">{{ 'general.all' | translate }}</mat-option>
        <mat-option [value]="scopes.Document">
          {{ 'header.search.result_documents' | translate }}</mat-option
        >
        <mat-option [value]="scopes.Equipment">
          {{ 'header.search.result_equipments' | translate }}</mat-option
        >
        <mat-option [value]="scopes.Product">
          {{ 'header.search.result_products' | translate }}</mat-option
        >
        <mat-option [value]="scopes.Sparepart">
          {{ 'header.search.result_spareparts' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="1 1 auto">
      <mat-label>{{ 'header.search.ph_search' | translate }}</mat-label>
      <input #searchInput matInput [formControl]="termForm" />
      <mat-error *ngIf="form.invalid">{{
        'header.search.hint' | translate
      }}</mat-error>
    </mat-form-field>
  </div>
  <div fxFlex="1 1 auto" class="scroll-container-vertical hidden-scollbar">
    <ds-search-history
      *ngIf="!termForm.value"
      [paldeskBasePath]="paldeskBasePath"
    ></ds-search-history>
    <ds-search-result
      [scope]="scopeForm.value"
      [paldeskBasePath]="paldeskBasePath"
    ></ds-search-result>
  </div>
</div>
