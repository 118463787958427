import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { matExpansionAnimations } from '@angular/material/expansion';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { dsAnimations } from '@design-system/cdk/animations';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AppWrapperService } from './app-wrapper.service';
import { Menu } from './menu/menu.component';

@Component({
  selector: 'ds-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss'],
  animations: [
    dsAnimations.fadeIn,
    dsAnimations.fadeOut,
    matExpansionAnimations.indicatorRotate,
  ],
})
export class AppWrapperComponent {
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  @Input() helpIconUrl: string;
  @Input() fastFeedbackAppId: string;
  @Input() helpOpenSameWindow: boolean;
  @Input() hasNoLogIn = false;
  @Input() hasSidebar = true;
  @Input() menu: Menu;
  @Input() menuLoadingCount: number;
  @Input() paldeskUrl = 'https://paldesk.palfinger.com';
  @Input() hideAppBar = false;

  drawerPortal?: ComponentPortal<any>;
  private destroy$ = new Subject<void>();

  isExpanded$: Observable<boolean>;

  manualSidebarToggle$: BehaviorSubject<boolean>;

  @HostListener('document:selectSiderbarNavigation')
  closeDrawer() {
    if (this.media.isActive('xs') && this.drawer) this.drawer.close();
  }

  constructor(
    public media: MediaObserver,
    private _location: Location,
    private _router: Router,
    private _breakpointObserver: BreakpointObserver,
    private wrapperService: AppWrapperService,
  ) {
    this.manualSidebarToggle$ = new BehaviorSubject<boolean>(
      localStorage.getItem('paldesk_sidebar_opened') !== 'false',
    );

    // close sidebar on navigation if screen size is small
    const navigationEvents$ = this._router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      withLatestFrom(
        this._breakpointObserver.observe([
          Breakpoints.Small,
          Breakpoints.XSmall,
        ]),
      ),
      filter(([, bpState]) => bpState.matches),
      map(() => false),
    );

    const breakpointSidebarToggle$ = this._breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map((bpState) => !bpState.matches),
        withLatestFrom(this.manualSidebarToggle$),
        // don't emit true if sidebar was closed manually
        filter(
          ([breakpointToggle, manualToggle]) =>
            !(breakpointToggle && !manualToggle),
        ),
        map(([breakpointToggle]) => breakpointToggle),
      );

    this.isExpanded$ = merge(
      breakpointSidebarToggle$,
      this.manualSidebarToggle$,
      navigationEvents$,
    );
  }

  navigateBack(): void {
    this._location.back();
  }

  toggleSidebar(expand: boolean): void {
    this.manualSidebarToggle$.next(expand);
    //TODO: Should be persisted in the user settings to have it across all apps.
    localStorage.setItem('paldesk_sidebar_opened', expand ? 'true' : 'false');
    const evt = document.createEvent('StorageEvent');
    evt.initStorageEvent('paldesk_sidebar_event');
    window.dispatchEvent(evt);
  }

  openDrawer(event: { component: ComponentType<any>; isMenu?: boolean }) {
    if (event.isMenu && this.menu) {
      this.drawerPortal = undefined;
    } else {
      this.drawerPortal = new ComponentPortal(event.component);
    }

    this.wrapperService.drawerOpen();
    this.wrapperService.drawerObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.drawer.open();
        } else {
          this.drawer.close();
          this.destroy$.next();
        }
      });
  }
}
