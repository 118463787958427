import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  SearchActions,
  SearchScopes,
  SearchSelector,
  SearchState,
} from '@features/search';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { PALDESK_BASEPATH } from '../environment';
@Component({
  selector: 'ds-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true })
  searchInput: any;
  paldeskBasePath: string;
  scopes = SearchScopes;
  readonly termForm = new UntypedFormControl(
    '',
    Validators.compose([Validators.minLength(3), Validators.required]),
  );
  readonly scopeForm = new UntypedFormControl('');
  readonly form = new UntypedFormGroup({
    term: this.termForm,
    scope: this.scopeForm,
  });
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<SearchState>,
    @Optional() @Inject(PALDESK_BASEPATH) basePath: string,
  ) {
    if (basePath) {
      this.paldeskBasePath = basePath;
    }
    this.store
      .select(SearchSelector.searchTerm)
      .pipe(take(1))
      .subscribe((x) => {
        this.termForm.patchValue(x, { emitEvent: false });
      });
    this.store
      .select(SearchSelector.scope)
      .pipe(take(1))
      .subscribe((x) => {
        this.scopeForm.patchValue(x || '', { emitEvent: false });
      });
  }

  ngOnInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 200); // we need to wait until the drawer is opened and is visible
    // check for changes and seach for item
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(() => {
          if (this.form.valid) {
            return true;
          } else {
            this.store.dispatch(SearchActions.Reset());
            return false;
          }
        }),
        tap((value) =>
          this.store.dispatch(
            SearchActions.Search({
              searchTerm: value.term,
              scope: value.scope,
            }),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
