import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnDestroy, Optional } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  ActivatedRoute,
  NavigationEnd,
  QueryParamsHandling,
  Router,
} from '@angular/router';
import { filter, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { PALDESK_BASEPATH } from '../environment';

export interface Menu {
  title: string;
  titleIcon?: string;
  equipmentIcon?: string;
  navigation: MenuItem[];
  backButtonUrl?: string;
}

export interface MenuItem {
  title: string;
  icon?: string;
  equipmentIcon?: string;
  externalLink?: string;
  routerLink?: string;
  children?: MenuItem[];
  badge?: string | number;
  isBadgeIcon?: boolean;
  queryParamsHandling?: QueryParamsHandling;
  onClick?: () => void;
  isHighlighted?: boolean;
}

@Component({
  selector: 'ds-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({ height: 0 }),
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ]),
      transition(':leave', [
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: 0 })),
      ]),
    ]),
  ],
})
export class MenuComponent implements OnDestroy {
  @Input() isExpanded: boolean;
  @Input() menu: Menu;
  @Input() menuLoadingCount: number;

  paldeskUrl = 'https://paldesk.palfinger.com';
  clickEvent = new Event('selectSiderbarNavigation');

  hideMenu$: Observable<boolean>;

  private opened: MenuItem[] = [];
  private readonly destroy$ = new Subject<void>();

  constructor(
    public media: MediaObserver,
    @Optional() @Inject(PALDESK_BASEPATH) basePath: string,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    if (basePath) {
      this.paldeskUrl = basePath;
    }
    this.hideMenu$ = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute.snapshot),
      map((rt) => {
        while (rt.firstChild) {
          rt = rt.firstChild;
        }
        return rt.data;
      }),
      map((data) => !!data?.hideMenu),
      startWith(false),
      takeUntil(this.destroy$),
    );
  }

  toggleOpened(item: MenuItem): void {
    if (this.opened.includes(item)) {
      this.opened = this.opened.filter((x) => x !== item);
    } else {
      this.opened.push(item);
    }
  }

  openItem($event: boolean, item: MenuItem): void {
    if ($event && !this.opened.includes(item)) {
      this.opened.push(item);
    }
  }

  isOpened(item: MenuItem): boolean {
    return this.opened.includes(item);
  }

  onItemClick(item?: MenuItem) {
    if (item?.onClick) {
      item.onClick();
    }
    document.dispatchEvent(this.clickEvent);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
