import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';

@Component({
  selector: 'ds-docu-code-snippet',
  templateUrl: './code-snippet.component.html',
  styleUrls: ['./code-snippet.component.scss'],
})
export class CodeSnippetComponent {
  @Input() code: string;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly snackbar: DsSnackbar,
  ) {}

  copySource(example: string) {
    if (this.clipboard.copy(example)) {
      this.snackbar.queue('Code copied', {
        type: DsSnackbarType.Success,
      });
    } else {
      this.snackbar.queue('Copy failed. Please try again!', {
        type: DsSnackbarType.Error,
      });
    }
  }
}
