import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { PollingUtils } from '@shared-lib/rxjs';
import { of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  HealthCheckBannerOptions,
  HEALTH_CHECK_OPTIONS_TOKEN,
} from './hc-status-config';
import { ServiceStatusResponse } from './models';

@Component({
  selector: 'paldesk-hc-status-banner',
  templateUrl: './hc-status-banner.component.html',
  styleUrls: ['./hc-status-banner.component.scss'],
})
export class HcStatusBannerComponent implements OnInit {
  showBanner$ = of(false);
  statusPaldeskPageLink: string;
  private readonly PaldesCoreHcApp = 'Paldesk Core';
  constructor(
    private httpClient: HttpClient,
    @Optional()
    @Inject(HEALTH_CHECK_OPTIONS_TOKEN)
    private config: HealthCheckBannerOptions,
    private messengerService: MessengerService,
  ) {}

  ngOnInit(): void {
    if (this.config?.statusPaldeskApiUri) {
      this.statusPaldeskPageLink = this.config.statusPaldeskPageUri;
      const hcCheckRequest$ = this.httpClient
        .post<ServiceStatusResponse[]>(this.config.statusPaldeskApiUri, {
          serviceNames: [...this.config.productsToCheck, this.PaldesCoreHcApp],
        })
        .pipe(take(1));
      this.showBanner$ = PollingUtils.polling(hcCheckRequest$, {
        interval: 300000,
      }).pipe(map((checks) => checks.some((x) => x.status !== 'Healthy')));
    } else {
      this.messengerService.sendMessage(
        MessageSeverityType.error,
        'Health Check Banner component not setup',
        MessageTargetType.console,
      );
    }
  }
}
