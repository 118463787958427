import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-docu-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent {
  @Input() heading = 'Please provide a title, Master!';
  @Input() link: string;
  @Input() imageUrl: string;
}
