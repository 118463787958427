import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export interface SidebarContext {
  resolver: ComponentFactoryResolver;
  injector: Injector;
}

@Injectable({
  providedIn: 'root',
})
export class DsSidebarService {
  context$: Observable<SidebarContext>;
  private contextSource = new ReplaySubject<SidebarContext>();

  constructor() {
    this.context$ = this.contextSource.asObservable();
  }

  setContext(
    componentFactoryResolver: ComponentFactoryResolver,
    injector: Injector,
  ) {
    this.contextSource.next({
      resolver: componentFactoryResolver,
      injector: injector,
    });
  }
}
