import { InjectionToken } from '@angular/core';

export interface ApiManagementOptions {
  headerName?: string;
  subscriptionKey: string;
  whitelistedDomains?: string[];
}

export interface AuthOptions {
  tokenGetter?: () => string | Promise<string>;
  headerName?: string;
  authScheme?: string;
  whitelistedDomains?: Array<string | RegExp>;
  whitelistedRoutes?: Array<string | RegExp>;
  blacklistedRoutes?: Array<string | RegExp>;
  throwNoTokenError?: boolean;
  skipWhenExpired?: boolean;
}

export class AuthFilledOptions {
  tokenGetter: () => string | Promise<string>;
  headerName: string;
  authScheme: string;
  whitelistedDomains: Array<string | RegExp>;
  whitelistedRoutes: Array<string | RegExp>;
  blacklistedRoutes: Array<string | RegExp>;
  throwNoTokenError: boolean;
  skipWhenExpired: boolean;
}

export const API_MANAGEMENT_CONFIG_TOKEN = new InjectionToken<
  ApiManagementOptions
>('API_MANAGEMENT_CONFIG_TOKEN');

export const IDENTITYSERVER_AUTH_CONFIG_TOKEN = new InjectionToken<AuthOptions>(
  'IDENTITYSERVER_AUTH_CONFIG_TOKEN',
);
