import { Inject, Injectable, Optional } from '@angular/core';
import { UserService } from '@features/auth';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, take } from 'rxjs';
import {
  FreshdeskWebwidgetConfig,
  FreshdeskWebwidgetConfigToken,
} from './freshdesk-widget-config';
@Injectable({
  providedIn: 'root',
})
export class FreshdeskWidgetService {
  labels = {} as any;
  init = new ReplaySubject<boolean>();

  constructor(
    private translateService: TranslateService,
    @Optional()
    @Inject(FreshdeskWebwidgetConfigToken)
    private config: FreshdeskWebwidgetConfig,
    @Optional() private userService: UserService,
  ) {
    if (config) {
      this.userService.currentUser.pipe(take(1)).subscribe((user) => {
        if (user) {
          const lang = user.lang ? user.lang.toLowerCase() : 'en';
          const window = this.getWindow();

          window.fwSettings = {
            widget_id: this.config.widgetId,
            locale: lang,
          };

          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          window.FreshworksWidget ||
            (function () {
              if ('function' != typeof window.FreshworksWidget) {
                const n: any = function () {
                  // eslint-disable-next-line prefer-rest-params
                  n['q'].push(arguments);
                };
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                (n['q'] = []), (window.FreshworksWidget = n);
              }
            })();

          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src = `https://euc-widget.freshworks.com/widgets/${this.config.widgetId}.js`;
          document.body.append(script);

          this.translateLabels(lang);
          this.FreshworksWidget('prefill', 'ticketForm', {
            name: `${user?.firstname} ${user?.lastname}`,
            email: user?.email,
          });

          this.init.next(true);
        }
      });
    }
  }

  showTicketWidgetIconLauncher() {
    this.FreshworksWidget('show', 'launcher');
  }

  /* Hides freshdesk widget for ticket creation on page */
  hideTicketWidgetIconLauncher() {
    if (this.FreshworksWidget) {
      this.FreshworksWidget('hide', 'launcher');
    }
  }

  openTheWidget() {
    this.FreshworksWidget('open');
  }

  hideTheWidget() {
    this.FreshworksWidget('close');
  }

  destroyTheWidget() {
    this.FreshworksWidget('destroy');
  }

  translateLabels(lang: string) {
    this.translateService
      .get([
        'freshdesk_widget.banner',
        'freshdesk_widget.title',
        'freshdesk_widget.submit',
        'freshdesk_widget.confirmation',
        'freshdesk_widget.launcher',
      ])
      .subscribe((data) => {
        this.labels[lang] = {
          banner: data['freshdesk_widget.banner'],
          launcher: data['freshdesk_widget.launcher'],
          contact_form: {
            title: data['freshdesk_widget.title'],
            submit: data['freshdesk_widget.submit'],
            confirmation: data['freshdesk_widget.confirmation'],
          },
        };
        this.FreshworksWidget('setLabels', this.labels);
      });
  }

  getWindow(): any {
    return window;
  }

  get FreshworksWidget(): any {
    const window = this.getWindow();
    return window.FreshworksWidget;
  }
}
