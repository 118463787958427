<div *ngIf="_intl && data; else loader">
  <div class="align-center" data-cy="dpa_template">
    <h3>{{ _intl.title }}</h3>
    <p>
      {{ _intl.according_to }}
      <br />
      {{ _intl.between }}
    </p>
    <br />
    <div class="party">
      <span>{{ data.first_name }} {{ data.last_name }}</span
      ><br />
      <span>{{ data.company_name }}</span
      ><br />
      <ng-container *ngIf="data.address">
        <span *ngIf="data.address.street">{{ data.address.street }}<br /></span>
        <span
          >{{ data.address.postal_code }} {{ data.address.city
          }}<strong *ngIf="data.address.country">{{
            ', ' + data.address.country
          }}</strong>
        </span>
      </ng-container>
    </div>
    <br />
    <p [innerHTML]="_intl.as_person_responsible"></p>
    <div class="party">
      <span>PALFINGER AG</span><br />
      <span> Lamprechtshausener Bundesstraße 8</span><br />
      <span>5101 Bergheim, Austria</span>
    </div>
    <br />
    <p [innerHTML]="_intl.as_processor"></p>
    {{ _intl.as_follows }}
    <br />
    <br />
  </div>
  <ol class="agreement-section" [innerHTML]="_intl.text"></ol>
  <div class="signature">
    <br />
    <br />
    <br />
    <div class="box">
      <ng-container *ngIf="_intl.in; else cityFirst">
        {{ data.date || today | date: 'shortDate' }}, {{ _intl.in }} Bergheim
      </ng-container>
      <br />
      {{ _intl.for_responsible_person }}:
      <div class="placeholder">
        <strong>{{ _intl.signed_by_two_factor }}</strong>
      </div>
      <span
        >{{ data.first_name + ' ' + data.last_name }}
        <span *ngIf="data.company_name">/ {{ data.company_name }}</span></span
      >
    </div>
    <div class="box">
      <ng-container *ngIf="_intl.in; else cityFirst">
        {{ data.date || today | date: 'shortDate' }}, {{ _intl.in }} Bergheim
      </ng-container>
      <br />
      {{ _intl.for_processor }}:
      <div class="placeholder">
        <img [src]="signatureUri" alt="" />
      </div>
      {{ responsiblePerson }} / GPM Services Land
    </div>
    <ng-template #cityFirst>
      Bergheim, {{ _intl.on }} {{ data.date || today | date: 'shortDate' }}
    </ng-template>
  </div>
</div>
<ng-template #loader>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
