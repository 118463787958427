import {
  colors,
  DsColors,
} from '../../../../../../styles/new-design/variables/colors';
import { sizes } from '../../../../../../styles/new-design/variables/spacing';

export interface DsConfig {
  spacing: number;
  borderRadius: number;
  colors: DsColors;
}

export const dsConfig: DsConfig = {
  spacing: sizes.spacing,
  borderRadius: sizes.borderRadius,
  colors,
};
