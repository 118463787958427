import { InjectionToken } from '@angular/core';

export const IDENTITY_SERVER_BASEPATH = new InjectionToken<string>(
  'identityServerBasePath',
);
export const PALDESK_BASEPATH = new InjectionToken<string>('paldeskBasePath');
export const PALDESK_SUPPORT_PATH = new InjectionToken<string>(
  'paldeskSupportPath',
);
export const TELEMATIC_SUPPORT_ENABLED = new InjectionToken<boolean>(
  'hasTelematicSupport',
);

export const PALDESK_DATA_PROTECTION_REGULATION_URL =
  new InjectionToken<string>('paldeskDataProtectionRegulationUrls');
