import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'ds-docu-table-of-contents',
  templateUrl: './table-of-contents.component.html',
  styleUrls: ['./table-of-contents.component.scss'],
})
export class TableOfContentsComponent implements OnInit {
  tableOfContents: Array<{
    elem: Element;
    children: Element[];
  }> = [];
  activeElement: Element;

  constructor(
    @Inject(DOCUMENT) private document: HTMLElement,
    public media: MediaObserver,
  ) {}

  gatherToc() {
    const allParents = this.document.querySelectorAll(
      '.page-content h2[data-toc]',
    );

    const allChildren = this.document.querySelectorAll(
      '.page-content h3[data-toc]',
    );

    allParents.forEach((e, index) => {
      const children: Element[] = [];
      const nextParent = allParents[index + 1];

      allChildren.forEach((child) => {
        if (
          child['offsetTop'] > e['offsetTop'] &&
          (!nextParent || child['offsetTop'] < nextParent['offsetTop'])
        ) {
          children.push(child);
        }
      });

      this.tableOfContents.push({
        elem: e,
        children,
      });
    });
  }

  ngOnInit() {
    setTimeout(() => this.gatherToc());
  }

  scrollToElement($element): void {
    this.activeElement = $element;
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
