import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { SearchEffects, searchReducer } from '@features/search';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { PaldeskHeaderLinkModule } from '../header-link/header-link.module';
import { SearchRecentComponent } from './search-recent/search-recent.component';
import { SearchResultOverlayComponent } from './search-result-overlay/search-result-overlay.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchComponent } from './search.component';
@NgModule({
  declarations: [
    SearchComponent,
    SearchResultOverlayComponent,
    SearchResultComponent,
    SearchRecentComponent,
  ],
  imports: [
    StoreModule.forFeature('searchModule', searchReducer, {}),
    EffectsModule.forFeature([SearchEffects]),
    RouterModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    Angulartics2Module,
    FlexLayoutModule,
    MatButtonModule,
    PaldeskHeaderLinkModule,
  ],
  exports: [SearchComponent, SearchResultComponent],
})
export class SearchModule {}
