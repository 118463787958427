import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SearchSelectors, SearchState } from './store';
import { SearchActions, SearchItem } from './store/search.actions';

@Component({
  selector: 'ds-docu-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnDestroy {
  base$: Observable<SearchItem[]>;
  results$: Observable<string[]>;
  isLoading$: Observable<boolean>;

  searchInput = new UntypedFormControl('');
  regex = /\s|\//g;

  private _destroy$ = new Subject<void>();

  constructor(private _searchStore: Store<SearchState>) {
    this._searchStore.dispatch(SearchActions.LoadBase());
    this.base$ = this._searchStore.select(SearchSelectors.getBase);
    this.results$ = this._searchStore.select(SearchSelectors.getResult);
    this.isLoading$ = this._searchStore.select(SearchSelectors.getIsLoading);

    this.searchInput.valueChanges
      .pipe(debounceTime(500), takeUntil(this._destroy$))
      .subscribe((input) => {
        if (input.length)
          this._searchStore.dispatch(
            SearchActions.GetResults({ search: input }),
          );
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
