import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { SearchResult } from '../search.model';
import { SearchRecentService } from '../services/search-recent.service';
import { SearchService } from '../services/search.service';
import { SearchActions } from '../store/search.actions';

@Injectable()
export class SearchEffects {
  search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.Search),
      debounceTime(300),
      map((action) => action),
      switchMap((query) => {
        if (query.searchTerm === '') {
          return EMPTY;
        }

        const nextSearch$ = this.actions$.pipe(
          ofType(SearchActions.Search),
          skip(1),
        );

        return this.searchService.search(query.searchTerm, query.scope).pipe(
          map((result) => SearchActions.SearchComplete({ payload: result })),
          catchError(() =>
            of(
              SearchActions.SearchComplete({
                payload: <SearchResult.Container>{},
              }),
            ),
          ),
          takeUntil(nextSearch$),
        );
      }),
    ),
  );

  loadSearchHistory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.LoadSearchHistory),
      switchMap(() => this.searchRecentService.getHistory().pipe(
          map((data) =>
            SearchActions.LoadSearchHistorySuccess({ payload: data }),
          ),
          catchError((_) => of(SearchActions.LoadSearchHistoryError())),
        )),
    ),
  );

  addSearchResult$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.AddSearchResult),
      map((a) => a),
      switchMap((payload) => this.searchRecentService
          .addResult(payload.key, payload.result)
          .pipe(
            map((data) =>
              SearchActions.AddSearchResultSuccess({ payload: data }),
            ),
            catchError((_) => of(SearchActions.AddSearchResultError())),
          )),
    ),
  );

  removeSearchResult$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.RemoveSearchResult),
      map((a) => a),
      switchMap((payload) => this.searchRecentService
          .removeResult(payload.key, payload.result)
          .pipe(
            map((data) =>
              SearchActions.RemoveSearchResultSuccess({ payload: data }),
            ),
            catchError((_) => of(SearchActions.AddSearchResultError())),
          )),
    ),
  );

  clearSearchHistory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.ClearSearchHistory),
      map((a) => a.payload),
      switchMap((key) => this.searchRecentService.clearHistory(key).pipe(
          map((data) =>
            SearchActions.ClearSearchHistorySuccess({ payload: data }),
          ),
          catchError((_) => of(SearchActions.ClearSearchHistoryError())),
        )),
    ),
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private searchRecentService: SearchRecentService,
  ) {}
}
