import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PdBootstrapFile } from './app-bootstrap.models';

export async function bootstrapPaldeskApp(
  appModule: any,
  isProduction: boolean,
  filesToLoadBeforeBootstraping: PdBootstrapFile[],
  googleKey?: string,
  googleLibraries?: string,
) {
  try {
    const files = await Promise.all(
      filesToLoadBeforeBootstraping.map(async (x) =>
        (await fetch(`${x.path}?timestamp=${Date.now()}`)).json(),
      ),
    );

    files.forEach((file, index) => {
      Object.assign(filesToLoadBeforeBootstraping[index].target, file);
    });

    if (isProduction) {
      enableProdMode();
    }
    if (googleKey) {
      const googleScript = document.createElement('script');
      googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=${
        googleLibraries ? googleLibraries : 'places'
      }`;
      googleScript.type = 'text/javascript';
      document.body.appendChild(googleScript);
    }

    platformBrowserDynamic()
      .bootstrapModule(appModule)
      .catch((err) => console.error(err));
  } catch (err) {
    console.error(err);
  }
}
