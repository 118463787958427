<div class="toc" *ngIf="tableOfContents.length && !media.isActive('xs')">
  <ul>
    <li><strong>Content</strong></li>
    <ng-container *ngFor="let item of tableOfContents">
      <li>
        <a
          class="no-underline"
          [class.active]="item.elem === activeElement"
          (click)="scrollToElement(item.elem)"
          >{{ item.elem.textContent }}</a
        >
        <ul *ngIf="item.children.length">
          <li *ngFor="let child of item.children">
            <a
              class="no-underline"
              [class.active]="child === activeElement"
              (click)="scrollToElement(child)"
              >{{ child.textContent }}</a
            >
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>
<div>
  <ng-content></ng-content>
</div>
