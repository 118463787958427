// register here all locales - en-US is per default registered
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHr from '@angular/common/locales/hr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localesPt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeSl from '@angular/common/locales/sl';
import localeSr from '@angular/common/locales/sr';
import localesSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeUk from '@angular/common/locales/uk';
import localeZh from '@angular/common/locales/zh';
import { LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { DateTime } from '@paldesk/shared-lib/utils/date-utils';
import { MultiTranslateHttpLoader } from './multi-http-loader';
import { PaginatorI18n } from './paginator-i18n';

registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeEnGb);
registerLocaleData(localeDe);
registerLocaleData(localeZh);
registerLocaleData(localeRu);
registerLocaleData(localeIt);
registerLocaleData(localeEs);
registerLocaleData(localesPt);
registerLocaleData(localesSv);
registerLocaleData(localeHr);
registerLocaleData(localeNl);
registerLocaleData(localeNb, 'no');
registerLocaleData(localeFi);
registerLocaleData(localeSl);
registerLocaleData(localeEl);
registerLocaleData(localeLt);
registerLocaleData(localeDa);

registerLocaleData(localeUk);
registerLocaleData(localeHu);
registerLocaleData(localeCs);
registerLocaleData(localeSk);
registerLocaleData(localePl);
registerLocaleData(localeRo);
registerLocaleData(localeBg);

registerLocaleData(localeLv);
registerLocaleData(localeSr);
registerLocaleData(localeTr);

export const paldeskSupportedLanguages = [
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'hr',
  'hu',
  'it',
  'lt',
  'lv',
  'no',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr',
  'sv',
  'tr',
  'uk',
  'zh',
];

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    {
      prefix: './assets/i18n/shared/',
      suffix: '.json',
    },
  ]);
}

// Returns the current browser Language if browser Language is supported
export function getBrowserLanguage(translateService: TranslateService): string {
  // set the browser language on login
  let browserLanguage = translateService.getBrowserLang();
  if (browserLanguage && browserLanguage.length > 2) {
    // use only two letters if browserLanguage is pt-BR
    browserLanguage = browserLanguage.substr(0, 2);
  }

  return browserLanguage && paldeskSupportedLanguages.includes(browserLanguage)
    ? browserLanguage
    : 'en';
}

function getEnglishWithCountry(browserLanguage?: string) {
  if (browserLanguage?.toLowerCase() === 'en-us') {
    return 'en-us';
  } else {
    return 'en-gb';
  }
}

// tslint:disable-next-line: use-primitive-type
class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }
  override toString() {
    return this.service.currentLang === 'en'
      ? getEnglishWithCountry(this.service.getBrowserLang())
      : this.service.currentLang || 'en';
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorI18n(translateService).getIntl(),
    },
  ],
  exports: [TranslateModule],
})
export class SharedLibFeatTranslationModule {
  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
  ) {
    this.translateService.setDefaultLang('en');
    const languageToUse = getBrowserLanguage(
      this.translateService,
    ).toLocaleLowerCase();

    if (languageToUse !== this.translateService.currentLang) {
      this.translateService.resetLang(languageToUse); // we need this as a workaround to a ngx-translate bug from 2007 :/ https://github.com/ngx-translate/core/issues/749
      this.translateService.use(languageToUse);
      DateTime.locale(languageToUse);
    }

    this.translateService.onLangChange.subscribe((langEvent) => {
      const changedLang =
        langEvent.lang === 'en'
          ? getEnglishWithCountry(this.translateService.getBrowserLang())
          : langEvent.lang;
      this.dateAdapter.setLocale(changedLang);
      DateTime.locale(changedLang);
    });
  }
}
