<button mat-list-item>
  <h5 class="no-margin">
    <small
      fxLayout
      fxLayoutAlign="start center"
      [fxLayoutGap]="0.5 | dsSpacing"
    >
      <span>{{
        allVisible
          ? ('header.search.collapse' | translate)
          : ('header.search.view_all' | translate)
      }}</span>
      <mat-icon [@indicatorRotate]="allVisible ? 'expanded' : 'collapsed'">
        expand_more
      </mat-icon>
    </small>
  </h5>
</button>
