<ng-container *ngIf="!submitted"
  ><h2 mat-dialog-title>{{ 'feedback_feature.share_feedback' | translate }}</h2>

  <div class="section">
    <p>{{ 'feedback_feature.satisfied' | translate }}</p>
    <mat-icon
      (click)="satisfyingExperience(true)"
      fontSet="pd"
      fontIcon="pd-sentiment-satisfied"
      class="smiley"
      [ngClass]="{
        initial: initialLoad || (!satisfaction && !initialLoad),
        satisfied: satisfaction && !initialLoad
      }"
    ></mat-icon>
    <mat-icon
      (click)="satisfyingExperience(false)"
      fontSet="pd"
      fontIcon="pd-sentiment-dissatisfied"
      class="smiley"
      [ngClass]="{
        initial: initialLoad || (satisfaction && !initialLoad),
        dissatisfied: !satisfaction && !initialLoad
      }"
    ></mat-icon>
  </div>
  <div class="form-grid section" fxLayout="row wrap">
    <mat-form-field fxFlex="100%">
      <mat-label>{{ 'feedback_feature.tell_us' | translate }}</mat-label>
      <textarea
        matInput
        maxlength="500"
        [(ngModel)]="experienceText"
        [cdkTextareaAutosize]="true"
        [cdkAutosizeMinRows]="1"
        [cdkAutosizeMaxRows]="2"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="section">
    <mat-checkbox [(ngModel)]="acceptContact">
      {{ 'feedback_feature.use_information' | translate }}
    </mat-checkbox>
  </div>
  <div mat-dialog-actions class="section">
    <button mat-raised-button (click)="cancel()">
      {{ 'feedback_feature.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="initialLoad"
      (click)="submitExperience()"
    >
      {{ 'feedback_feature.submit' | translate }}
    </button>
  </div>
</ng-container>
<ng-container *ngIf="submitted">
  <h2 mat-dialog-title>{{ 'feedback_feature.thank_you' | translate }}</h2>
  <ds-placeholder type="success"> </ds-placeholder>
</ng-container>
