import { CountryResponse, RegionResponse } from '@data-access/bpm-generated';
import { createAction, props } from '@ngrx/store';

export enum RootActionTypes {
  ShowLoadingSpinner = '[ROOT] SHOW_LOADING_SPINNER',
  UpdateLoadingSpinner = '[ROOT] UPDATE_LOADING_SPINNER',
  HideLoadingSpinner = '[ROOT] HIDE_LOADING_SPINNER',
  ShowSuccessMessage = '[ROOT] SHOW_SUCCESS_MESSAGE',
  ShowInfoMessage = '[ROOT] SHOW_INFO_MESSAGE',
  ShowWarningMessage = '[ROOT] SHOW_WARNING_MESSAGE',
  ShowErrorMessage = '[ROOT] SHOW_ERROR_MESSAGE',
  GetCountries = '[ROOT] GET_COUNTRIES',
  GetCountriesSuccess = '[ROOT]  GET_COUNTRIES_SUCCESS',
  GetRegions = '[ROOT] GET_REGIONS',
  GetRegionsSuccess = '[ROOT] GET_REGIONS_SUCCESS',
  GetRegionsError = '[ROOT] GET_REGIONS_ERROR',
  GetRegionsErrorReset = '[ROOT] GET_REGIONS_ERROR_RESET',
}

export namespace RootActions {
  export const ShowLoadingSpinner = createAction(
    RootActionTypes.ShowLoadingSpinner,
  );
  export const UpdateLoadingSpinner = createAction(
    RootActionTypes.UpdateLoadingSpinner,
    props<{ payload?: number }>(),
  );
  export const HideLoadingSpinner = createAction(
    RootActionTypes.HideLoadingSpinner,
  );

  /**
   * @param payload translation key
   */
  export const ShowSuccessMessage = createAction(
    RootActionTypes.ShowSuccessMessage,
    props<{ payload: string | null }>(),
  );
  /**
   * @param payload translation key
   */
  export const ShowInfoMessage = createAction(
    RootActionTypes.ShowInfoMessage,
    props<{ payload: string }>(),
  );
  /**
   * @param payload translation key
   */
  export const ShowWarningMessage = createAction(
    RootActionTypes.ShowWarningMessage,
    props<{ payload: string }>(),
  );
  /**
   * @param message or HttpErrorResponse
   */
  export const ShowErrorMessage = createAction(
    RootActionTypes.ShowErrorMessage,
    // eslint-disable-next-line @typescript-eslint/ban-types
    props<{ payload?: string | object }>(),
  );

  /**
   * @param lang language
   */
  export const GetCountries = createAction(
    RootActionTypes.GetCountries,
    props<{ payload: string }>(),
  );

  /**
   * @param contries CountryResponse[]
   */
  export const GetCountriesSuccess = createAction(
    RootActionTypes.GetCountriesSuccess,
    props<{ key: string; countryList: CountryResponse[] }>(),
  );

  /**
   * @param lang language
   */
  export const GetRegions = createAction(
    RootActionTypes.GetRegions,
    props<{ country: string; language: string }>(),
  );

  export const GetRegionsSuccess = createAction(
    RootActionTypes.GetRegionsSuccess,
    props<{
      country: string;
      language: string;
      regionList: RegionResponse[];
    }>(),
  );

  export const GetRegionsError = createAction(
    RootActionTypes.GetRegionsError,
    props<any>(),
  );

  export const GetRegionsErrorReset = createAction(
    RootActionTypes.GetRegionsErrorReset,
  );
}
