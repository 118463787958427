import { Component } from '@angular/core';

@Component({
  selector: 'ds-page-sidebar-item',
  templateUrl: './page-sidebar-item.component.html',
  styleUrls: ['./page-sidebar-item.component.scss'],
})
export class DsPageSidebarItemComponent {
  dispatchSelect() {
    const click = new Event('selectSiderbarNavigation');
    document.dispatchEvent(click);
  }
}
