<mat-card
  [class]="type"
  class="banner"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <ds-text-indicator [type]="type" [noColor]="true">
    <ng-content></ng-content>
  </ds-text-indicator>
  <ng-content select="[slot=end]"></ng-content>
</mat-card>
