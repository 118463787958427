import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SearchActions, SearchItem } from './search.actions';
import * as lunr from 'lunr';

@Injectable()
export class SearchEffects {
  LoadBase$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(SearchActions.LoadBase),
      switchMap(() =>
        this._httpClient
          .get('assets/results-index.json')
          .pipe(
            map((data) =>
              SearchActions.LoadBaseSuccess({ base: data as SearchItem[] }),
            ),
          ),
      ),
    ),
  );

  GetResults$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(SearchActions.GetResults),
      switchMap(({ search }) =>
        this._httpClient.get('assets/lunr-index.json').pipe(
          map((loadedIndex) =>
            SearchActions.GetResultsSuccess({
              result: lunr.Index.load(loadedIndex)
                .search(search + ' *' + search + '*')
                .map((result) => result.ref),
            }),
          ),
        ),
      ),
    ),
  );

  constructor(private _actions$: Actions, private _httpClient: HttpClient) {}
}
