<mat-dialog-content>
  <div class="logo">
    <img src="assets/img/logo.svg" />
  </div>
  <paldesk-dpa-template
    [contract]="contract"
    [data]="data"
  ></paldesk-dpa-template>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close cdkFocusInitial>
    {{ 'general.close' | translate }}
  </button>
  <button mat-raised-button (click)="printDPA()">
    {{ 'general.print' | translate }}
  </button>
  <button
    *ngIf="data.accept_needed"
    mat-raised-button
    color="primary"
    (click)="accept()"
    data-cy="accept_dpa"
  >
    {{ 'general.accept' | translate }}
  </button>
  <mat-form-field class="language-selector">
    <mat-select
      placeholder="{{ 'general.language' | translate }}"
      [(value)]="language"
      (selectionChange)="changeLanguage()"
    >
      <mat-option *ngFor="let lang of availableLanguages" [value]="lang">{{
        lang | uppercase
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-actions>
