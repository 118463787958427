import { AuthConfig } from 'angular-oauth2-oidc';

export class OidcConfig {
  public static Configuration(
    securityTokenService: string,
    oidcClientId: string,
    oidcScope: string,
    postLogoutRedirectUri: string,
  ): AuthConfig {
    return {
      issuer: securityTokenService,
      redirectUri: getBaseUrl() + 'callback',
      clientId: oidcClientId,
      responseType: 'code',
      scope: oidcScope,
      postLogoutRedirectUri: postLogoutRedirectUri,
      sessionChecksEnabled: true,
      useSilentRefresh: true,
      silentRefreshRedirectUri: getBaseUrl() + 'assets/silent-refresh.html',
      // showDebugInformation: true, // uncomment for debugging authentication issues
      clockSkewInSec: 18000, // 5 hours = 5 * 60 * 60
      loginUrl: securityTokenService + '/connect/authorize',
      tokenEndpoint: securityTokenService + '/connect/token',
      userinfoEndpoint: securityTokenService + '/connect/userinfo',
      logoutUrl: securityTokenService + '/connect/endsession',
      sessionCheckIFrameUrl: securityTokenService + '/connect/checksession',
      silentRefreshTimeout: 15000,
    };
  }
}

export function getBaseUrl() {
  if (document.getElementsByTagName('base')[0]) {
    return document.getElementsByTagName('base')[0].href;
  }

  return '';
}
