<div
  fxLayout="column"
  fxLayoutAlign="space-between center"
  [fxLayoutGap]="1 | dsSpacing"
  class="full-height full-width"
>
  <div
    *ngIf="
      userService?.isAuthorized && userService?.userContext;
      else loginAction
    "
    class="full-width"
  >
    <div class="padding">
      <h2 class="no-margin" data-cy="user-headline">
        {{ userService.userContext.firstname }}
        {{ userService.userContext.lastname }}<br />
        <small>{{ userService.userContext.company_name }}</small>
      </h2>
    </div>
    <mat-action-list>
      <a
        data-cy="user-profile-button"
        mat-list-item
        (click)="openUserProfile()"
      >
        <mat-icon>account_circle</mat-icon>
        {{ 'general.profile' | translate }}
      </a>
      <a
        mat-list-item
        [href]="paldeskBasePath + '/pat'"
        target="_blank"
        data-cy="user-security-button"
      >
        <mat-icon>build</mat-icon>
        {{ 'general.security' | translate }}
      </a>
      <a
        mat-list-item
        data-cy="reset-spotlight-button"
        (click)="resetSpotlight()"
      >
        <mat-icon ds-page-sidebar-item-icon> flare </mat-icon>
        {{ 'reset_spotlight' | translate }}
      </a>
      <button mat-list-item (click)="logout()" data-cy="user-logout-button">
        <mat-icon>power_settings_new</mat-icon>
        {{ 'general.logout' | translate }}
      </button>
    </mat-action-list>
  </div>
  <ng-template #loginAction>
    <mat-action-list class="full-width">
      <button mat-list-item (click)="login()" data-cy="login-button">
        <mat-icon>vpn_key</mat-icon>
        {{ 'general.login' | translate }}
      </button>
    </mat-action-list>
  </ng-template>
  <mat-action-list class="full-width">
    <a
      mat-list-item
      *ngIf="hasTelematicSupport"
      data-cy="privacy-statement-button"
    >
      <mat-icon>security</mat-icon>
      {{ 'footer.privacy_statement' | translate }}
    </a>
    <a
      *ngIf="paldeskDataProtectionRegulationUrl"
      mat-list-item
      [href]="paldeskDataProtectionRegulationUrl"
      data-cy="data-protection-button"
    >
      <mat-icon>privacy_tip</mat-icon>
      {{ 'footer.data_protection' | translate }}
    </a>
    <a
      mat-list-item
      href="https://vdp.palfinger.com/"
      data-cy="vulnerability-policy-button"
    >
      <mat-icon>policy</mat-icon>
      {{ 'footer.vulnerability_policy' | translate }}
    </a>
    <a
      data-cy="user-help-support-button"
      mat-list-item
      href="mailto:paldesk@palfinger.com?subject=Support for PALDESK Issue"
    >
      <mat-icon>help_center</mat-icon>
      {{ 'footer.support_button_text' | translate }}
    </a>
    <a mat-list-item [href]="paldeskSupportPath" data-cy="user-status-button">
      <mat-icon>traffic</mat-icon>
      PALDESK Status
    </a>
    <span mat-list-item class="mat-list-mock" data-cy="user-copyright"
      ><mat-icon>copyright</mat-icon> PALFINGER AG {{ copyrightYear }}</span
    >
  </mat-action-list>
</div>
