import {
  bootstrapPaldeskApp,
  PdBootstrapFile,
} from '@paldesk/shared-lib/app-bootstrap';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const filesToLoadBeforeBootstraping: PdBootstrapFile[] = [
  {
    target: environment,
    path: environment.production
      ? 'assets/config/config.json'
      : 'assets/config/config.development.json',
  },
];

bootstrapPaldeskApp(
  AppModule,
  environment.production,
  filesToLoadBeforeBootstraping,
  environment.production
    ? 'AIzaSyBEzxjzzLoOYmqsdFMxX8DuWJn6tearZ4g'
    : 'AIzaSyDyebmhQe0ZVqB89DbsnE7qiym8KiaQpWo',
);
