import { CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ds-docu-christmas',
  templateUrl: './christmas.component.html',
  styleUrls: ['./christmas.component.scss'],
})
export class ChristmasComponent {
  @ViewChild('tree') tree: ElementRef;
  icons = [
    '001_Loader_Cranes.png',
    '002_Railway.png',
    '003_Access_Platforms.png',
    '004_Truck_Mounted_Forklifts.png',
    '006_Tail Lifts.png',
    '007_Container_Handling_Systems.png',
    '010_Epsilon_Timber_and_Recycling.png',
    '011_Skiploader.png',
    '013_Marine cranes.png',
    '026_Wind cranes.png',
    '031_Service_Cranes_NA.png',
    '032_Truck_bodies_NA_update_2018.png',
    '034_PAL_PRO_Mechanic_Trucks_NA.png',
    '035_Large_Aerial_Platforms_NA.png',
    '036_Cable_Hoists_NA.png',
    '037_Hoists_NA.png',
    '039_Pick_up_Lifts_NA.png',
    '040_Access_Platform_PPI_Smart.png',
    '044_Passenger_Lifts_Update.png',
    '045_Bridge_Inspection_Units.png',
    '046_Offshore cranes.png',
    '047_Lifesaving equipment.png',
    '048_Life and rescue boats.png',
    '049_Winches.png',
    '050_Forestry_and_Scrap_Cranes_NA.png',
    '052_Inman.png',
    '053_ETI_Solutions_NA.png',
    '054_Davits.png',
    '056_ETI_Solutions_NA.png',
    '057_ETI_Solutions_NA.png',
  ];

  drop(event: any) {
    const { x, y } = this.tree.nativeElement.getBoundingClientRect();
    const insideTree =
      event.dropPoint.x > x &&
      event.dropPoint.x < x + this.tree.nativeElement.offsetWidth &&
      event.dropPoint.y > y &&
      event.dropPoint.y < y + this.tree.nativeElement.offsetHeight;
    const class_list =
      event.source.element.nativeElement.parentElement?.classList;
    if (insideTree) {
      class_list.add('inside-tree');
    } else {
      class_list.remove('inside-tree');
    }
  }

  started(event: CdkDragStart<any>) {
    event.source.element.nativeElement.parentElement?.classList.add(
      'stop-animation',
    );
  }
}
