import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import {
  PALDESK_BASEPATH,
  PALDESK_SUPPORT_PATH,
} from '@design-system/feature/app-wrapper';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { SEARCH_SERVICE_BASE_PATH } from '@features/search';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    StoreModule.forRoot([], {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // SIDEBAR
    {
      provide: PALDESK_BASEPATH,
      useFactory: () => environment.LINKS.PALDESK_BASEPATH,
    },
    {
      provide: PALDESK_SUPPORT_PATH,
      useFactory: () => environment.LINKS.PALDESK_SUPPORT_PATH,
    },

    {
      provide: SEARCH_SERVICE_BASE_PATH,
      useFactory: () =>
        environment.palfinger_api_management + '/search/v1/search',
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () =>
        environment.palfinger_api_management + '/business-partner-management',
    },
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
  ],
})
export class CoreModule {}
