import { createFeatureSelector, createSelector } from '@ngrx/store';
import { key, SearchState } from './search.reducers';

const selectSearch = createFeatureSelector<SearchState>(key);

export namespace SearchSelectors {
  export const getBase = createSelector(
    selectSearch,
    (state) => state.searchBase,
  );

  export const getIsLoading = createSelector(
    selectSearch,
    (state) => state.isLoading,
  );

  export const getResult = createSelector(
    selectSearch,
    (state) => state.searchResult,
  );
}
