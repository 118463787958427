import { Pipe, PipeTransform } from '@angular/core';
import { SearchItem } from '../store/search.actions';

@Pipe({
  name: 'findRoute',
})
export class FindRoutePipe implements PipeTransform {
  transform(title: string, base: Array<SearchItem> | null): string | undefined {
    if (base?.length && title.length) {
      return base.find((result) => title === result.title)?.route;
    } else {
      return undefined;
    }
  }
}
