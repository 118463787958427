/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MediaMarshaller } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule],
})
export class DsPrintModule {
  lastValue;

  public constructor(m: MediaMarshaller) {
    // hack until resolve: https://github.com/angular/flex-layout/issues/1201

    // @ts-ignore
    m.subject.subscribe((x) => {
      // @ts-ignore
      if (
        // @ts-ignore
        m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0
      ) {
        // @ts-ignore
        this.lastValue = [...m.activatedBreakpoints];
      } else {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        m.hook.collectActivations = () => {};
        // @ts-ignore
        m.hook.deactivations = [...this.lastValue];
      }
    });
  }
}
