import { Component, Input, OnInit } from '@angular/core';
import {
  SearchActions,
  SearchResultLink,
  SearchSelector,
  SearchState,
} from '@features/search';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
@Component({
  selector: 'ds-search-history',
  templateUrl: './search-history.component.html',
  styleUrls: ['./search-history.component.scss'],
})
export class SearchHistoryComponent implements OnInit {
  @Input() paldeskBasePath: string;
  searchIdentifier = 'global-search';
  history$: Observable<SearchResultLink[]>;

  constructor(private store: Store<SearchState>) {}

  ngOnInit(): void {
    this.history$ = this.store.pipe(
      select(SearchSelector.searchHistory),
      filter((h) => !(!h || !h.results)),
      map((h) => (!h ? [] : h.results[this.searchIdentifier])),
      distinctUntilChanged((x, y) => isEqual(x, y)),
    );
    this.store.dispatch(SearchActions.LoadSearchHistory());
  }

  removeResult(result: SearchResultLink) {
    this.store.dispatch(
      SearchActions.RemoveSearchResult({
        key: this.searchIdentifier,
        result: result,
      }),
    );
  }

  clearHistory() {
    this.store.dispatch(
      SearchActions.ClearSearchHistory({ payload: this.searchIdentifier }),
    );
  }
}
