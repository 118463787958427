<div
  class="sidebar-container full-height"
  fxLayout
  [ngClass]="{ animate: animateSidebar }"
  [style.width]="100 * sidebarContents.length + '%'"
  [style.margin-left]="-100 * (sidebarContents.length - 1) + '%'"
>
  <ds-page-sidebar-content
    [fxFlex]="100 / sidebarContents.length + '%'"
    *ngFor="let content of sidebarContents; let i = index"
    [component]="content.component"
    [isExpanded]="isExpanded"
    [backUrl]="i > 0 ? backUrl || sidebarContents[i - 1].url : undefined"
    [paldeskUrl]="paldeskUrl"
  ></ds-page-sidebar-content>
</div>
