import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { SearchHistoryComponent } from './search-history/search-history.component';
import { SearchResultModule } from './search-result/search-result.module';
import { SearchComponent } from './search.component';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    SearchResultModule,
    DsSpacingModule,
    TranslateModule,
  ],
  declarations: [SearchComponent, SearchHistoryComponent],
  exports: [SearchComponent],
})
export class SearchModule {}
