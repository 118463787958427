<h1 class="no-margin">{{ 'general.applications' | translate }}</h1>
<ng-container *ngIf="applicationCategories; else loading">
  <ng-container *ngFor="let category of applicationCategories">
    <h4>
      <small>{{ category.name | uppercase }}</small>
    </h4>
    <mat-action-list dense>
      <mat-list-item *ngFor="let app of category.applications">
        <a
          fxFlex="1 1 auto"
          class="no-underline"
          href="{{ app.url }}"
          target="{{ app.url_target }}"
        >
          <span class="icon-holder">
            <ng-container *ngIf="app.icon; else shortName">
              <ng-container
                *ngIf="app.icon?.indexOf('http') === -1; else externalIcon"
              >
                <ng-container
                  *ngIf="app.icon?.indexOf('pd') === -1; else pdIcon"
                >
                  <mat-icon>{{ app.icon }}</mat-icon>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #pdIcon>
              <mat-icon fontSet="pd" [fontIcon]="app.icon"> </mat-icon>
            </ng-template>
            <ng-template #externalIcon>
              <div class="icon">
                <img src="{{ app.icon }}" />
              </div>
            </ng-template>
            <ng-template #shortName>
              {{ app.name | appShortName }}
            </ng-template>
          </span>
          {{ app.name }}
        </a>
        <mat-icon
          [fxHide]="!canToggleFavorites && app.favorite"
          inline
          (click)="toggleFavourite(app)"
          >{{ app.favorite ? 'star' : 'star_outline' }}</mat-icon
        >
      </mat-list-item>
    </mat-action-list>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="full-height overflow-hidden">
    <div
      class="loading-container"
      fxLayout="column"
      [fxLayoutGap]="1 | dsSpacing"
      *ngFor="let i of [1, 2, 3]"
    >
      <ds-loading-placeholder *ngFor="let i of [1, 2]"></ds-loading-placeholder>
    </div>
  </div>
</ng-template>
