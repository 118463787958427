<div
  *ngIf="class !== 'add'; else addPlaceholder"
  class="full-width full-height placeholder"
  [ngClass]="class"
  data-test="placeholder"
>
  <ng-container *ngTemplateOutlet="placeholder"></ng-container>
</div>

<ng-template #addPlaceholder>
  <button
    mat-button
    class="full-width full-height placeholder add"
    matRippleDisabled
  >
    <ng-container *ngTemplateOutlet="placeholder"></ng-container>
  </button>
</ng-template>

<ng-template #placeholder>
  <div
    class="full-width padding text-center full-height"
    fxLayout="column"
    [fxLayoutGap]="0.5 | dsSpacing"
    fxLayoutAlign="center center"
  >
    <div *ngIf="!isLoadingState; else loadingView">
      <img
        [style.height]="iconSize ? iconSize : (6 | dsSpacing)"
        [style.width]="iconSize ? iconSize : (6 | dsSpacing)"
        *ngIf="
          (icon && icon.indexOf('assets') !== -1) ||
            (icon && icon.indexOf('http') !== -1);
          else matIconTemplate
        "
        [src]="icon"
      />
      <ng-template #matIconTemplate>
        <mat-icon
          *ngIf="!(icon | isPdIcon)"
          [ngClass]="class"
          [style.height]="iconSize ? iconSize : (6 | dsSpacing)"
          [style.width]="iconSize ? iconSize : (6 | dsSpacing)"
          [style.fontSize]="iconSize ? iconSize : (6 | dsSpacing)"
          >{{ icon }}</mat-icon
        >
        <mat-icon
          [style.height]="iconSize ? iconSize : (6 | dsSpacing)"
          [style.width]="iconSize ? iconSize : (6 | dsSpacing)"
          [style.fontSize]="iconSize ? iconSize : (6 | dsSpacing)"
          [ngClass]="class"
          fontSet="pd"
          [fontIcon]="icon"
          [color]="color"
          *ngIf="icon | isPdIcon"
        ></mat-icon>
      </ng-template>
    </div>
    <ng-template #loadingView>
      <ds-loading-placeholder
        [width]="iconSize ? iconSize : (6 | dsSpacing)"
        [height]="iconSize ? iconSize : (6 | dsSpacing)"
        [isRound]="true"
      ></ds-loading-placeholder>
    </ng-template>

    <h4
      class="no-margin-top"
      *ngIf="!isLoadingState; else loadingHeadline"
      [innerHTML]="customHeadline ? customHeadline : (headline | translate)"
    ></h4>
    <ng-template #loadingHeadline>
      <ds-loading-placeholder
        class="h2 no-margin-top"
        [width]="20 | dsSpacing"
        [height]="1.5 | dsSpacing"
      ></ds-loading-placeholder>
    </ng-template>
    <span>
      <ng-content></ng-content>
    </span>
  </div>
</ng-template>
