import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationInsightsService } from '@shared-lib/app-insights';

@Component({
  selector: 'ds-feedback-feature-dialog',
  templateUrl: './feedback-feature-dialog.component.html',
  styleUrls: ['./feedback-feature-dialog.component.scss'],
})
export class FeedbackFeatureDialogComponent {
  satisfaction: boolean;
  initialLoad = true;
  experienceText = '';
  acceptContact = false;
  submitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeedbackFeatureDialogComponent>,
    private appInsightsService: ApplicationInsightsService,
  ) {}

  satisfyingExperience(satisfied: boolean) {
    this.initialLoad = false;
    this.satisfaction = satisfied;
  }

  submitExperience() {
    this.appInsightsService.storeFastFeedback({
      message: this.experienceText,
      happy: this.satisfaction ? 5 : 1,
      contactMe: this.acceptContact,
      appId: this.data.appId,
      screen: this.data.screen,
    });
    this.submitted = true;
    setTimeout(() => {
      this.cancel();
    }, 1500);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
