import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchResult, SearchResultLink } from '@features/search';
import { SearchResultComponent } from '../shared/search-result/search-result.component';

@Component({
  selector: 'ds-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss'],
})
export class EquipmentsComponent extends SearchResultComponent {
  @Input() equipments: SearchResult.Equipment[];
  @Input() set viewAllByDefault(value: boolean) {
    this.viewAll = value;
  }
  @Input() paldeskBasePath: string;
  @Output() itemClick = new EventEmitter<SearchResultLink>();
}
