import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AzureApiManagementInterceptor } from './azure-api-management.interceptor';
import { IdentityServerAuthInterceptor } from './identityserver.auth.interceptor';
import { AuthOptions, IDENTITYSERVER_AUTH_CONFIG_TOKEN } from './models';
import { HttpInterceptorTokens } from './tokens';

export function identityServerAuthOptionsFactory(
  oauthService: OAuthService,
  identityServerAuthConf: string,
): AuthOptions {
  const authOptions = identityServerAuthConf as AuthOptions;
  if (oauthService) {
    authOptions.tokenGetter = function () {
      return oauthService.getAccessToken();
    };
  }
  return authOptions;
}

export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AzureApiManagementInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: IdentityServerAuthInterceptor,
    multi: true,
  },
];

const AuthConfigTokens = [
  {
    provide: IDENTITYSERVER_AUTH_CONFIG_TOKEN,
    useFactory: identityServerAuthOptionsFactory,
    deps: [
      [new Optional(), OAuthService],
      HttpInterceptorTokens.identityServerAuthConf,
    ],
  },
];

@NgModule()
export class HttpInterceptorModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: HttpInterceptorModule,
  ) {
    if (parentModule) {
      throw new Error(
        "HttpInterceptorModule is already loaded. It should only be imported in your application's main module.",
      );
    }
  }

  static forRoot(
    apiManagementOptionsProvider: Provider,
  ): ModuleWithProviders<HttpInterceptorModule> {
    return {
      ngModule: HttpInterceptorModule,
      providers: [
        apiManagementOptionsProvider,
        AuthConfigTokens,
        HttpInterceptorProviders,
      ],
    };
  }
}
