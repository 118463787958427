import { Injectable } from '@angular/core';

// Code comes from https://github.com/auth0/angular2-jwt/blob/master/src/jwthelper.service.ts
// urlBase64Decode was simplified
@Injectable({
  providedIn: 'root',
})
export class AuthTokenHelperService {
  getTokenExpirationDate(token: string): Date | undefined {
    const decoded = this.decodeToken(token);

    // eslint-disable-next-line no-prototype-builtins
    if (!decoded.hasOwnProperty('exp')) {
      return undefined;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  isTokenExpired(token: string, offsetSeconds?: number): boolean {
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (!date) {
      return true;
    }

    return date.valueOf() <= new Date().valueOf() + offsetSeconds * 1000;
  }

  decodeToken(token: string): any {
    if (!token) {
      return undefined;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error(
        "The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.",
      );
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token.');
    }

    return JSON.parse(decoded);
  }

  replaceInvalidTokenStrings(str: string): string {
    return str.replace(/-/g, '+').replace(/_/g, '/');
  }

  urlBase64Decode(str: string) {
    let output = this.replaceInvalidTokenStrings(str);
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw Error('Illegal base64url string!');
    }

    return decodeURIComponent(escape(atob(output)));
  }
}
